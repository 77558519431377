<template>
   <div class="w-full h-full flex flex-col justify-center items-center">
      <svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-20 w-20"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
      >
         <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
         />
      </svg>
      <h1 class="uppercase font-semibold text-3xl">
         Errore 404
      </h1>
      <h2>Spiacenti, questa pagina non esiste.</h2>
   </div>
</template>

<script>
export default {
   name: "PageNotFound",

   mounted() {
   },
};
</script>
